/* Base Styles */
body,
html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensure content starts from the top */
  align-items: center;
  overflow: auto; /* Allow overflow */
  background: linear-gradient(
    247deg,
    rgba(240, 144, 144, 0.83) 0%,
    #e379a9 50.01%,
    #ee9258 100%
  );
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
  background: linear-gradient(
    247deg,
    rgba(240, 144, 144, 0.83) 0%,
    #e379a9 50.01%,
    #ee9258 100%
  );
  padding: 20px;
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allow the navbar to wrap its items */
}

.nav-link {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  color: #fff;
  background-color: #d74d8b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  text-decoration: underline;
  background-color: #f1823c;
}

/* Portfolio Styles */
.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-grow: 1; /* Allow the container to grow and fill available space */
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px; /* Add some space between the text and the images */
}

.pic {
  margin: 10px; /* Add some margin between images */
  max-width: 40%;
  max-height: 40%;
  object-fit: contain; /* Ensure the image scales properly */
}

/* Introduction Styles */
.introduction-container {
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-grow: 1; /* Allow the container to grow and fill available space */
}

.introduction-title {
  font-size: 2rem;
  margin: 20px 0;
}

.introduction-content {
  font-size: 1.25rem;
  line-height: 1.5;
  word-wrap: break-word; /* Ensures long words break to the next line */
  overflow-wrap: break-word; /* Alternative to word-wrap for better browser support */
  max-width: 100%; /* Ensures the content doesn't exceed the container's width */
  white-space: normal; /* Ensures the text wraps normally within the container */
}

/* Logo Styles */
.logo {
  width: 150px; /* Make the logo smaller */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Add some space below the logo */
  object-fit: contain; /* Ensure the image scales properly */
  align-self: center; /* Center the image */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    margin: 10px 0;
  }

  .image-wrapper {
    flex-direction: column;
  }

  .pic {
    max-width: 80%;
    max-height: 30%;
  }
}

@media (min-width: 769px) {
  .container {
    padding: 40px;
  }

  .navbar {
    flex-direction: row;
    justify-content: space-around;
  }

  .pic {
    max-width: 30%;
    max-height: 30%;
  }
}

/* Image Styles */
.image-container {
  display: flex; /* Ensure it's a flex container */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  width: 100%; /* Make sure it takes up the full width of its parent */
  overflow: hidden; /* Hide any overflow */
  margin: 20px 0; /* Add some margin to create space around the image */
}

.image-container img {
  max-width: 100%; /* Make sure the image scales down to fit the container */
  height: auto; /* Maintain aspect ratio */
}

/* Music Styles */
.music-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.music-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.music-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.audio-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.audio-item {
  flex: 1 1 calc(33.333% - 20px);
  min-width: 200px;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.audio-title {
  font-size: 18px;
  color: #444;
  margin-bottom: 10px;
}

audio {
  width: 100%;
  margin-top: 10px;
}

button {
  margin-top: 10px;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  background-color: #d74d8b;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #f1823c;
}

.lyrics {
  margin-top: 10px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 4px;
  text-align: left;
  max-height: 150px;
  overflow-y: auto;
  white-space: pre-wrap;
}
